import { head, map } from 'lodash/fp'
import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import data from './pgn'

const columns = [
  { Header: 'PGN', accessor: 'PGN' },
  { Header: 'Name', accessor: 'Name' },
  { Header: 'Category', accessor: 'Category' },
  { Header: 'Description', accessor: 'Description' },
]
class App extends Component {
  render() {
    const rows = map(head, data)
    return (
      <div className="App">
        <ReactTable
          data={rows}
          columns={columns}
          minRows={1}
          pageSizeOptions={[100, 200, 300, 400]}
          defaultPageSize={300}
          filterable
        />
      </div>
    )
  }
}

export default App
